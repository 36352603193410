// External Settings
import "./ocmSettings";
// API bereitstellen (für cms Cockpit /  ...)
import "./ocmApi";
// API bereitstellen (für cms Cockpit /  ...)
import "./initComponents";

if (document.getElementById("accountPage") != null) {
	import(/* webpackChunkName: "myAccount" */ "../../myAccount/myAccount");
}
