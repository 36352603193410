/**
 * init components
 */

import * as resize from "@sharedJS/resize";
import * as formElements from "@sharedJS/formElements";
import * as categoryBar from "@sharedJS/categoryBar";
import * as accordion from "@sharedJS/accordion";
import * as accordionNavigation from "@sharedJS/accordionNavigation";
import * as scrollbar from "@classicJS/scrollbar";
import * as popUpOverlay from "@sharedJS/popUpOverlay";
import * as offcanvas from "@sharedJS/offcanvas";
import * as offcanvasSearch from "@sharedJS/offcanvasSearch";
import * as clearSearch from "@sharedJS/clearSearch";
import * as tooltip from "@sharedJS/tooltip";
import * as modal from "@sharedJS/modal";
import * as infoModals from "@sharedJS/infoModals";
import * as dropdown from "@sharedJS/dropdown";
import * as slider from "@sharedJS/slider";
import * as gotop from "@sharedJS/gotop";
import * as classicNewsletter from "@classicJS/classicNewsletter";
import * as classicMaps from "@classicJS/classicMaps";
import * as classicCityMap from "@classicJS/classicCityMap";
import * as close from "@classicJS/close";
import * as slot from "@sharedJS/slot";
import header from "@sharedJS/header";
import * as headerNavigation from "@sharedJS/headerNavigation";
import * as flyout from "@sharedJS/flyout";
import * as teaserHeaderMore from "@sharedJS/teaserHeaderMore";
import * as popupMessagesLayer from "@sharedJS/popupMessagesLayer";
import * as classicPopUpLayer from "@classicJS/classicPopUpLayer";
import * as brandListMenu from "@classicJS/brandListMenu";
import * as shorten from "@classicJS/textShorten";
import * as faqAccordion from "@classicJS/faqAccordion";
import * as classicFilter from "@classicJS/classicFilter";
import * as instafeed from "@classicJS/instafeed";
import * as circleProgress from "@classicJS/circleProgress";
import * as classicImageSlider from "@classicJS/classicImageSlider";
import * as classicToggleOfferTile from "@classicJS/classicToggleOfferTile";
import * as classicBrandDetailToggleOffer from "@classicJS/classicBrandDetailToggleOffer";
import * as classicShopProductSlider from "@classicJS/classicShopProductSlider";
import * as classicOfferSlider from "@classicJS/classicOfferSlider";
import * as classicSeoImageSlider from "@classicJS/classicSeoImageSlider";
import classicSeoImage from "@classicJS/classicSeoImage";
import * as classicSeoText from "@classicJS/classicSeoText";
import * as classicFilterMobile from "@classicJS/classicFilterMobile";
import * as classicSortMobile from "@classicJS/classicSortMobile";
import * as classicDropDownFilter from "@classicJS/classicDropDownFilter";
import * as classicTextboxSlider from "@classicJS/classicTextboxSliderPager";
import * as infiniteScroll from "@classicJS/infiniteScroll";
import * as careerRenderer from "@classicJS/careerRenderer";

import * as classicTileDescriptionShortener from "@classicJS/classicTileDescriptionShortener";
import * as searchResults from "@classicJS/searchResults";
import * as timeline from "@classicJS/timeline";
import * as dateMaskField from "@sharedJS/dateMaskField";
import * as brandteaser from "@classicJS/brandteaser";
import * as loyaltyMigration from "@sharedJS/loyaltyMigration";
import * as scrollToLabel from "@sharedJS/scrollToLabel";

import * as logoAnimation from "@sharedJS/logoAnimation";
import * as seoLinkMask from "@sharedJS/seoLinkMask";
import * as lazyLoader from "@sharedJS/lazyLoader";
import { initAjaxPrefilter } from "@sharedJS/ajaxprefilter";
import * as svgIcons from "@sharedJS/svgIcons";

svgIcons.init();

jQuery(document).ready(() => {
	// General
	initAjaxPrefilter();
	resize.init();
	formElements.init();
	categoryBar.init();
	accordion.init();
	accordionNavigation.init();
	scrollbar.init();
	popUpOverlay.init();
	offcanvas.init();
	offcanvasSearch.init();
	clearSearch.init();
	tooltip.init();
	modal.init();
	infoModals.init();
	dropdown.init();
	slider.init();
	gotop.init();
	classicNewsletter.init();
	classicMaps.init();
	classicCityMap.init();
	close.init();

	// Department Home
	slot.init();

	// Header
	header.init();
	headerNavigation.init();
	flyout.init();

	// Category
	teaserHeaderMore.init();

	// Popup Messages Layer
	popupMessagesLayer.init();
	classicPopUpLayer.init();

	// FAQ
	faqAccordion.init();

	// Brand List
	brandListMenu.init();

	// Classic OCM
	shorten.init();
	classicFilter.init();
	instafeed.init();
	circleProgress.init();
	classicImageSlider.init();

	// Classic Offer Overview
	classicToggleOfferTile.init();

	// Classic Brand Detail
	classicBrandDetailToggleOffer.init();
	classicShopProductSlider.init();
	classicOfferSlider.init();

	// SEO-Image Slider
	classicSeoImageSlider.init();
	classicSeoImage.init();

	// SEO-Text
	classicSeoText.init();

	// Classic Filter
	classicFilterMobile.init();
	classicSortMobile.init();
	classicDropDownFilter.init();

	// Classic Pager for all Textbox Sliders
	classicTextboxSlider.init();

	// Infinite Scroll
	infiniteScroll.init();

	// Career
	careerRenderer.init();

	// Tile Shortener
	classicTileDescriptionShortener.init();

	// Search Autocomplete
	searchResults.init();

	// Timeline About Us
	timeline.init();

	// DateMaskField
	dateMaskField.init();

	brandteaser.init();

	// Scroll to Label
	scrollToLabel.init();

	//Accept Loyalty Terms and Conditions
	loyaltyMigration.init();
	logoAnimation.init();
	lazyLoader.init();

	seoLinkMask.init();
});
