// Use Messenger for global communication inter SPA or between Vue and global JS.
// Inside Vue2 SPA use Event Bus instead of Messenger.

const messengerPublicationTypes = {
	// VISUAL PROPERTIES
	HEADER_NAV_HEIGHT_CHANGED: "HEADER_NAV_HEIGHT_CHANGED",

	// Login
	REQUEST_LOGIN: "REQUEST_LOGIN",
	ABORTED_LOGIN: "ABORTED_LOGIN",
	LOGGED_IN: "LOGGED_IN",
	LOGGED_OUT: "LOGGED_OUT",
	FACEBOOK_LOGIN: "FACEBOOK_LOGIN",
	GOOGLE_LOGIN: "GOOGLE_LOGIN",

	// Product List
	SAVE_PRODUCTLIST_STATE: "SAVE_PRODUCTLIST_STATE",

	// Product Detail
	ENTER_PRODUCTDETAIL: "ENTER_PRODUCTDETAIL",
	LEAVE_PRODUCTDETAIL: "LEAVE_PRODUCTDETAIL",

	// Wishlist
	ADDED_TO_WISHLIST: "ADDED_TO_WISHLIST",
	UPDATE_MULTIPLE_WISHLIST: "UPDATE_MULTIPLE_WISHLIST",
	UPDATE_MULTIPLE_WISHLIST_ERROR: "UPDATE_MULTIPLE_WISHLIST_ERROR",
	REMOVED_FROM_WISHLIST: "REMOVED_FROM_WISHLIST",
	UNMOUNTED_WISHLIST: "UNMOUNTED_WISHLIST",

	// Cart
	UPDATED_MINI_CART: "UPDATED_MINI_CART",
	UPDATED_CART_SAVING: "UPDATED_CART_SAVING",
	UNMOUNTED_CART: "UNMOUNTED_CART",

	// Suche
	OPEN_SEARCH: "OPEN_SEARCH",
	TRIGGER_SEARCH: "TRIGGER_SEARCH",
	CLOSE_SEARCH: "CLOSE_SEARCH",
	ADD_TOAST: "ADD_TOAST",
	ADD_EMARSYS_TOAST: "ADD_EMARSYS_TOAST",
	ADD_BANNER: "ADD_BANNER",

	// Single SPA
	ROUTE_CHANGE_MOUNTED_APPS: "ROUTE_CHANGE_MOUNTED_APPS",
	ROUTE_CHANGE: "ROUTE_CHANGE",

	// Navigation
	NAVIGATION_CHANGE: "NAVIGATION_CHANGE",

	// Thank You Page
	ADD_EMARSYS_PHONENUMBER_MODAL: "ADD_EMARSYS_PHONENUMBER_MODAL",
};

const messengerSubscriptionTypes = {
	REACT_ONLY_ONCE: 'REACT_ONLY_ONCE',
	REACT_TO_LATEST: 'REACT_TO_LATEST'
};

export { messengerPublicationTypes, messengerSubscriptionTypes};
