/**
 * 8select add to Cart
 * nur auf der Shop The Look Seite
 */

import * as utils from "@sharedJS/utils";
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";

let popup;
let popupContent;
const ajaxRequest = new XMLHttpRequest();
ajaxRequest.addEventListener("load", onComplete);

function addToCart(productID) {
	if (doPageReloadForCoopUser() !== false || !document.querySelector("[data-8select-widget-id]")) {
		return;
	}

	const url = utils.getUrl(`/rest/cart/product/${productID}`);
	popup = document.querySelector("#js-cart-popup");
	popupContent = popup?.querySelector(".popupContent");

	try {
		ajaxRequest.open("PUT", url);
		ajaxRequest.setRequestHeader("accept", "application/json");
		ajaxRequest.setRequestHeader("CSRFToken", ocm.config.CSRFToken);
		ajaxRequest.send(null);
	} catch (error) {
		console.error(error);
	}
}

function onComplete() {
	if (!popupContent) return;
	try {
		const responseData = JSON.parse(this.responseText);
		this.status === 200 ? onSuccess(responseData) : onError(responseData);
	} catch (error) {
		console.error(error);
	}
}

function onSuccess(data) {
	const cartUrl = utils.getUrl(`/cart/`);
	popupContent.innerHTML = buildHTML(data, cartUrl);
	ocm.popUpOverlay.openPopup(popup, { blur: true });
	OcmMessenger.publish(messengerPublicationTypes.UPDATED_MINI_CART);
}

function onError(data) {
	popupContent.innerHTML = buildErrorHTML(data);
	ocm.popUpOverlay.openPopup(popup, { blur: true });
}

function buildHTML({ headline, message }, cartUrl) {
	return `<div class="c-cart-popup__headline"><h3>${headline}</h3></div>
		<div class="c-cart-popup__content">
			<div class="c-cart-popup__message-success u-inline-block"><span class="c-cart-popup__icon-success c-icon c-icon--hook-2"></span>${message}</div>
			<a href="${cartUrl}" class="c-cart-popup__action-button o-btn c-btn--normal">Warenkorb ansehen</a>
		</div>`;
}

function buildErrorHTML({ headline = "Ein Fehler ist aufgetreten" }) {
	return `<div class="c-cart-popup__headline"></div>
		<div class="c-cart-popup__content">
			<div class="cart_popup_error_msg u-error-text u-inline-block">${headline}</div>
		</div>`;
}

function doPageReloadForCoopUser() {
	const currentAuthLevel = _getAuthLevel();
	const coopLevel = 200;
	if (currentAuthLevel === coopLevel) {
		// TODO TUB-7822: rewrite to use the new reauth/login handling without 'precheck' ...
		window.location = utils.getUrl("/");
		return true;
	}
	return false;
}

function _getAuthLevel() {
	let authLevel = 0;
	$.ajax({
		url: utils.getUrl("/authLevel/"),
		global: false,
		type: "GET",
		dataType: "html",
		async: false, // Important !!!
	}).success((data) => {
		authLevel = +(data ?? 0);
	});
	return authLevel;
}

export { addToCart };
