// Functions for header flyouts: second level navigation, user account, cart
import { searchClose } from "@sharedJS/closeSearch";
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

// DOM
let $flyouts;
let $body;

// DOM Attribute Names
const FLYOUT_DELAYED_CLASS = "js-flyout--delayed";
const FLYOUT_IS_OPEN_CLASS = "is-open";

const BODY_SELECTOR = "body";
const FLYOUT_SELECTOR = ".js-flyout";
const FLYDOWNBUTTON_SELECTOR = ".js-navigation-flydown-button";
const FLYOUT_IS_OPEN_SELECTOR = ".is-open";
const FLYOUT_CONTAINER_SELECTOR = ".js-flyout-container";

const PREVENTFLYOUTONPAGE_ATTRIBUT = "data-preventflyoutonpage";

// Settings / State
let isTouchDevice;
let currentlyOpenFlyout;

function init() {
	$body = $(BODY_SELECTOR);
	$flyouts = $(FLYOUT_SELECTOR);
	if (!$flyouts.length) return;

	isTouchDevice = Modernizr.touchevents;
	_initFlyouts($flyouts);

	// Flyouts with PreventFlyoutOnPage, e.g. for the cart flyout should not be opened on the cart page
	const $flyoutsWithPreventFlyoutOnPage = $flyouts.filter(`[${PREVENTFLYOUTONPAGE_ATTRIBUT}]`);
	checkForPreventFlyoutOnPage($flyoutsWithPreventFlyoutOnPage);

	// React to Route Changes
	OcmMessenger.subscribe(messengerPublicationTypes.ROUTE_CHANGE, () => {
		if (currentlyOpenFlyout) handleCloseFlyout(currentlyOpenFlyout);
		checkForPreventFlyoutOnPage($flyoutsWithPreventFlyoutOnPage);
	});
}

function _initFlyouts($flyouts) {
	$flyouts.each(function () {
		// init Flyouts on hover or touch
		const $flyout = $(this);
		if (!isTouchDevice && $flyout.hasClass(FLYOUT_DELAYED_CLASS)) {
			addFlyoutDelayedListener($flyout);
		} else if (!isTouchDevice) {
			addFlyoutHoverListener($flyout);
		} else {
			addFlyoutTouchListener($flyout);
		}

		// init Flyout-Buttons for Accessibility
		const $flyDownButton = $flyout.find(FLYDOWNBUTTON_SELECTOR);
		if ($flyDownButton.length) addFlydownButtonListener($flyDownButton, $flyout);
	});
}

//+++++++++++++++++++++++++
// Initial Listeners
//+++++++++++++++++++++++++

function addFlyoutDelayedListener($flyout) {
	$flyout.hoverIntent({
		over: () => handleOpenFlyout($flyout),
		out: () => handleCloseFlyout($flyout),
		timeout: 250,
	});
}

function addFlyoutHoverListener($flyout) {
	$flyout.hover(
		() => handleOpenFlyout($flyout),
		() => handleCloseFlyout($flyout)
	);
}

function addFlyoutTouchListener($flyout) {
	$flyout
		.children("a")
		.first()
		.on("touchstart", (event) => {
			const currentTargetHref = event.currentTarget.getAttribute("href");
			if (!$flyout.hasClass(FLYOUT_IS_OPEN_CLASS) && event.cancelable) {
				event.preventDefault();
				handleOpenFlyout($flyout);
			} else if (currentTargetHref === "#" || currentTargetHref === "javascript:void(0)") {
				handleCloseFlyout($flyout);
			}
		});

	$flyout.on("touchend", (event) => {
		event.stopPropagation();
		if (
			event.target.classList.contains("c-header-content-navigationlist__linkbutton") &&
			event.target.hasAttribute("onclick")
		) {
			window.setTimeout(handleCloseFlyout, 100, $flyout);
		}
	});
}

function addFlydownButtonListener($button, $flyout) {
	const IS_TOGGLE_BUTTON_FOCUSED = "toggle-is-focused";
	$button.on("click", (event) => {
		event.stopPropagation(); // stop login-layer from opening
		handleToggleFlyout($flyout);
	});
	$button.on("focus", () => {
		$flyout.addClass(IS_TOGGLE_BUTTON_FOCUSED);
	});
	$button.on("blur", () => {
		$flyout.removeClass(IS_TOGGLE_BUTTON_FOCUSED);
	});
}

function handleToggleFlyout($flyout) {
	if ($flyout.hasClass(FLYOUT_IS_OPEN_CLASS)) {
		handleCloseFlyout($flyout);
	} else {
		handleOpenFlyout($flyout);
	}
}

//+++++++++++++++++++++++++++++++++++++++
// dynamic Listeners (on open and close)
//+++++++++++++++++++++++++++++++++++++++

let boundCloseFlyoutWhenFocusIsOutside;
let boundCloseOnEscPress;

function addOnFocusChangeCloseFlyoutListener($flyout) {
	const $currentFlyoutContainer = $flyout.find(FLYOUT_CONTAINER_SELECTOR).first();
	boundCloseFlyoutWhenFocusIsOutside = closeFlyoutWhenFocusIsOutside.bind(this, $flyout, $currentFlyoutContainer);
	boundCloseOnEscPress = closeOnEscPress.bind(this, $flyout);
	document.addEventListener("focusin", boundCloseFlyoutWhenFocusIsOutside);
	document.addEventListener("keydown", boundCloseOnEscPress);
}

function removeOnFocusChangeCloseFlyoutListener() {
	document.removeEventListener("focusin", boundCloseFlyoutWhenFocusIsOutside);
	document.removeEventListener("keydown", boundCloseOnEscPress);
}

function addCloseFlyoutOnBodyTouch() {
	// Close Flyout when clicked outside the flyouts
	const $body = $("body");
	$body.off("touchend", closeFlyoutOnBodyTouch);
	$body.on("touchend", closeFlyoutOnBodyTouch);
}

function closeOnEscPress($flyout, event) {
	if (event.key === "Escape" && $flyout.hasClass(FLYOUT_IS_OPEN_CLASS)) {
		handleCloseFlyout($flyout);
		$flyout.find(FLYDOWNBUTTON_SELECTOR).focus();
	}
}

function closeFlyoutWhenFocusIsOutside($flyout, $currentFlyoutContainer, event) {
	if ($currentFlyoutContainer.length && !$currentFlyoutContainer.has(event.target).length) {
		handleCloseFlyout($flyout);
	}
}

function closeFlyoutOnBodyTouch(event) {
	// timeout to let any kind of default behaviour happen
	if (
		(event.target instanceof HTMLInputElement && event.target.type === "text") ||
		event.target instanceof HTMLButtonElement
	) {
		// abort the following if clicked into an input/text aka searchfield or button
		return;
	}

	window.setTimeout(() => {
		handleCloseFlyout($(`${FLYOUT_SELECTOR}${FLYOUT_IS_OPEN_SELECTOR}`));
		$("body").off("touchend", closeFlyoutOnBodyTouch);
	}, 100);
}

//+++++++++++++++++++++++++
// open and close functions
//+++++++++++++++++++++++++

function handleOpenFlyout($flyout) {
	// check for flyout to be disabled
	if ($flyout.data("flyout-disabled")) return;

	// close previous flyouts
	if ($flyout !== currentlyOpenFlyout) handleCloseFlyout(currentlyOpenFlyout);

	// check for Buttons to be active
	const preventFlyoutOnPage =
		$flyout.data("preventflyoutonpage") && window.location.pathname.endsWith(preventFlyoutOnPage);
	if (!$flyout || preventFlyoutOnPage) return;

	$flyout.addClass(FLYOUT_IS_OPEN_CLASS);
	// add class to body if any secondary-nav-flyout is open
	if ($flyout.hasClass("js-secondary-nav__item")) $body.addClass("secondary-flyout-is-open");
	searchClose();
	addOnFocusChangeCloseFlyoutListener($flyout);
	if (isTouchDevice) addCloseFlyoutOnBodyTouch();
	$flyout.find(FLYDOWNBUTTON_SELECTOR).attr("aria-expanded", "true");
	currentlyOpenFlyout = $flyout;
}

function handleCloseFlyout($flyout) {
	// always close current flyout
	if ($flyout) {
		$flyout.removeClass(FLYOUT_IS_OPEN_CLASS);
		$flyout.find(FLYDOWNBUTTON_SELECTOR).attr("aria-expanded", "false");
		// remove class from body if no flyout is open
	}
	// remove class from body if no flyout is open
	if ($flyouts.filter(".is-open").length === 0) $body.removeClass("secondary-flyout-is-open");
	removeOnFocusChangeCloseFlyoutListener();
	currentlyOpenFlyout = null;
}

//+++++++++++++++++++++++++
// Helper Functions
//+++++++++++++++++++++++++

function checkForPreventFlyoutOnPage($flyoutsWithPreventFlyoutOnPage) {
	// check for Buttons and flyout to be disabled
	$flyoutsWithPreventFlyoutOnPage.each(function () {
		const $flyout = $(this);
		const preventFlyoutOnPage = $flyout.data("preventflyoutonpage");
		const $button = $flyout.find(FLYDOWNBUTTON_SELECTOR);

		if (preventFlyoutOnPage && window.location.pathname.endsWith(preventFlyoutOnPage)) {
			$flyout.data("flyout-disabled", true);
			$button.attr("aria-disabled", "true");
			$button.attr("disabled", "disabled");
		} else {
			$flyout.data("flyout-disabled", false);
			$button.removeAttr("aria-disabled");
			$button.removeAttr("disabled");
		}
	});
}

export { init };
